import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import "../scss/legal.scss"
import CookieConsentContext from "../context/CookieContext";
import CookiePopup from "../components/cookiepopup";

const TermsPage = () => (
  <CookieConsentContext.Consumer>
    {({ state, dispatch, popupIsOpen }) => (
    <div className="legal">
      <Helmet
        htmlAttributes={{
          class: 'legal-page'
        }}
        bodyAttributes={{
        class: 'legal-page'
      }}>
         <title>Όροι χρήσης</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Όροι χρήσης</h1>
      <p>Η ιστοσελίδα quarantine.souldesign.gr ανήκει στην εταιρεία με την επωνυμία SoulDesign που εδρεύει στην Αθήνα οδός Μυκάλης 11, Κεραμείκός, 10435. και τα σχετικά με αυτήν δικαιώματα και υποχρεώσεις διέπονται από τους όρους και προϋποθέσεις που παρατίθενται στο παρόν και στα αναπόσπαστα τμήματά του και ισχύουν για το σύνολο του περιεχομένου της και των επιμέρους σελίδων της.</p>
      <p>Η ιστοσελίδα απευθύνεται μόνο σε νομικά ή φυσικά πρόσωπα με πλήρη δικαιοπρακτική ικανότητα που μπορούν να συνάψουν νομικά δεσμευτικές συμφωνίες στο πλαίσιο της ισχύουσας νομοθεσίας.</p>
      <p>Η περιήγηση, η πρόσβαση ή η χρήση της ιστοσελίδας και των όποιων υπηρεσιών διατίθενται μέσω αυτής αποτελεί τεκμήριο ότι ο επισκέπτης/χρήστης έχει μελετήσει, κατανοήσει και αποδεχτεί όλους τους όρους χρήσης. Για το λόγο αυτό ο επισκέπτης καλείται να διαβάζει εκ των προτέρων το περιεχόμενου τους. Σε περίπτωση που ο επισκέπτης/ χρήστης δεν συμφωνεί με τους όρους χρήσης της ιστοσελίδας, οφείλει να μην κάνει χρήση των υπηρεσιών και του περιεχομένου της.</p>
      <p>Η εταιρεία διατηρεί το δικαίωμα μονομερούς τροποποίησης των όρων και προϋποθέσεων οποτεδήποτε και χωρίς προειδοποίηση. Η εταιρία θα αναρτά διαδικτυακά την εκάστοτε ισχύουσα έκδοση των όρων χρήσης, ενώ η συνέχιση της χρήσης της ιστοσελίδας ή των υπηρεσιών της θα θεωρείται ότι συνιστά αποδοχή των νέων όρων. Για το λόγο αυτό κάθε χρήστης παρακαλείται να ελέγχει ανά τακτά χρονικά διαστήματα τους όρους χρήσης.</p>
      <p>Οι όροι χρήσης της ιστοσελίδας, καθώς και κάθε τροποποίησή τους, διέπονται από το εθνικό και το κοινοτικό δίκαιο και τυχόν εφαρμοστέες σχετικές διεθνείς συνθήκες. Οποιαδήποτε διάταξη των ανωτέρω όρων διαπιστωθεί ότι είναι αντίθετη με το ως άνω νομικό πλαίσιο ή καταστεί εκτός ισχύος, παύει αυτοδίκαια να ισχύει και αφαιρείται από το παρόν, χωρίς σε καμία περίπτωση να θίγεται η ισχύς των λοιπών όρων.</p>
      <p>Ομοίως, σε περίπτωση που κάποιοι όροι χρήσης καταστούν μερικώς ή ολικώς άκυροι ή μη εφαρμοστέοι, δεν επηρεάζεται η ισχύς ή/και η εγκυρότητα των υπολοίπων όρων ή μέρους αυτών. Οι άκυροι ή/και μη εφαρμοστέοι όροι θα αντικαθίστανται με όρους που θα πλησιάζουν όσο είναι δυνατόν το νόημα και το σκοπό των άκυρων ή μη εφαρμοστέων όρων.</p>
      <p>Διαφορές που τυχόν προκύπτουν από την εφαρμογή των όρων και την εν γένει χρήση της ιστοσελίδας από τον επισκέπτη ή χρήστη αυτής, θα επιλύονται καταρχήν φιλικά, αν όμως αυτό δεν καταστεί εφικτό θα διέπονται από το ελληνικό δίκαιο και θα υπάγονται στην αποκλειστική αρμοδιότητα των Δικαστηρίων της Αθήνας.</p>
      <p>Αναπόσπαστο και δεσμευτικό μέρος των όρων χρήσης αποτελεί επίσης και η Δήλωση απορρήτου και προστασίας Προσωπικών Δεδομένων καθώς και η ακολουθούμενη πολιτική cookies της εταιρείας.</p>
      <h2>Δικαιώματα Πνευματικής Ιδιοκτησίας (Copyright) – Εμπορικά σήματα  SoulDesign</h2>
      <p>Το περιεχόμενο της ιστοσελίδας αποτελεί πνευματική ιδιοκτησία της SoulDesign και προστατεύεται από το ισχύον εθνικό, κοινοτικό και διεθνές δίκαιο. Απαγορεύεται οποιαδήποτε τροποποίηση, δημοσίευση, μετάδοση, μεταβίβαση, αναπαραγωγή, διανομή, παρουσίαση, σύνδεση, φόρτωση (download) ή με άλλον τρόπο εκμετάλλευση, εν όλω ή εν μέρει, του περιεχομένου της ιστοσελίδας με οποιονδήποτε τρόπο ή μέσο, χωρίς την προηγούμενη ρητή και έγγραφη άδειά της, η οποία επιφυλάσσεται παντός νομίμου δικαιώματός της.</p>
      <p>Το περιεχόμενο της ιστοσελίδας διατίθεται στους επισκέπτες/ χρήστες της για προσωπική χρήση και απαγορεύεται να διατίθεται για εμπορικούς σκοπούς. Τα προϊόντα ή υπηρεσίες τρίτων που τυχόν αναφέρονται στην παρούσα ιστοσελίδα και φέρουν τα σήματα των αντίστοιχων οργανισμών, εταιρειών, συνεργατών φορέων, ενώσεων ή εκδόσεων, αποτελούν δική τους πνευματική και βιομηχανική ιδιοκτησία.</p>
      <h2>Χρήση συνδέσμων σε δικτυακούς τόπους τρίτων (links)</h2>
      <p>Σε περίπτωση που η ιστοσελίδα παρέχει τώρα ή στο μέλλον τη δυνατότητα πρόσβασης σε ιστοσελίδες τρίτων μέσα από συνδέσμους (links), αυτό θα γίνεται αποκλειστικά για τη διευκόλυνση των επισκεπτών/ χρηστών της, ενώ η χρήση των συνδέσμων αυτών θα γίνεται με αποκλειστική ευθύνη των επισκεπτών/χρηστών και οι αντίστοιχες ιστοσελίδες θα υπόκεινται στους δικούς τους όρους χρήσης, για τους οποίους ουδεμία ευθύνη φέρει η   εταιρία. Η εταιρία δεν εγγυάται τη διαθεσιμότητά των δικτυακών τόπων (web-sites) τρίτων και δεν εγκρίνει ούτε ευθύνεται για το περιεχόμενο, την ορθότητα, νομιμότητα, πληρότητα, επικαιρότητα και ακρίβεια των πληροφοριών ούτε για την ποιότητα και τις ιδιότητες των προϊόντων ή υπηρεσιών, τα οποία διατίθενται από τα πρόσωπα αυτά μέσω των ανωτέρω διαδικτυακών τόπων. Η εταιρία δεν ευθύνεται, επίσης, για σφάλματα ή κακή λειτουργία των ιστοσελίδων των τρίτων, καθώς και για οποιαδήποτε ζημία των χρηστών από την πρόσβαση και χρήση των παρεχομένων μέσω αυτών πληροφοριών, υπηρεσιών και προϊόντων.</p>
      <h2>Εγκυρότητα της ιστοσελίδας – Προστασία από Ιούς - Περιορισμός ευθύνης - Δήλωση αποποίησης</h2>
      <p>H ιστοσελίδα μπορεί να περιέχει ανακρίβειες ή τυπογραφικά λάθη, τα οποία θα διορθώνονται από την εταιρία κατά την κρίση της, εφόσον εντοπίζονται. H εταιρία δεν δύναται ωστόσο να εγγυηθεί για την ακρίβεια, πληρότητα, ορθότητα, επικαιρότητα ή μη παραβίαση του περιεχομένου της ιστοσελίδας για οποιαδήποτε χρήση, εφαρμογή ή σκοπό. Επίσης, δεν εγγυάται ότι τυχόν λάθη θα διορθώνονται απαραιτήτως ή εξολοκλήρου ή ότι θα δίνονται απαντήσεις σε όλα τα ερωτήματα που θα τίθενται.
        Παρόλο που η εταιρία καταβάλλει κάθε δυνατή προσπάθεια προκειμένου να αποκλείσει την εμφάνιση ιών σε αυτή την ιστοσελίδα, δεν εγγυάται ότι οι λειτουργίες της ιστοσελίδας θα είναι αδιάλειπτες ή απαλλαγμένες από κάθε είδους σφάλματα και ιούς και δεν ευθύνεται για οποιαδήποτε απώλεια δεδομένων ή άλλη ζημία του επισκέπτη/χρήστη ή τρίτων, οφειλόμενη είτε σε χρήση/αντιγραφή/φόρτωση (download) είτε σε αλλοίωση ή μόλυνση με ιούς ή άλλες μη επιτρεπόμενες παρεμβάσεις τρίτων σε αρχεία και πληροφορίες που διατίθενται μέσω της ιστοσελίδας.
        Κάθε επισκέπτης οφείλει να λαμβάνει όλα τα κατάλληλα μέτρα ασφάλειας (π.χ. προγράμματα προστασίας από ιούς) πριν από οποιαδήποτε ‘’φόρτωση’’ από αυτή την ιστοσελίδα. Το κόστος των ενδεχόμενων διορθώσεων ή επισκευών, το αναλαμβάνει ο επισκέπτης/ χρήστης και σε καμία περίπτωση η SoulDesign.
      </p>
      <p>Η SoulDesign δεν φέρει ευθύνη για την προσωρινή μη διαθεσιμότητα της ιστοσελίδας, για την διακοπή μεμονωμένων ή όλων των λειτουργιών ή εφαρμογών της ή για οποιαδήποτε δυσλειτουργία ή τεχνικά θέματα τυχόν εμφανισθούν.</p>
      <p>Η SoulDesign υπό οποιεσδήποτε συνθήκες, συμπεριλαμβανομένης και της περίπτωσης αμέλειας, δεν ευθύνεται έναντι του επισκέπτη/χρήστη ή οποιουδήποτε τρίτου για οποιασδήποτε μορφής ζημία, άμεση ή έμμεση, θετική η αποθετική, που σχετίζεται με οποιοδήποτε τρόπο με τη χρήση της ιστοσελίδας, την πλοήγηση σε αυτή & τη χρήση των υπηρεσιών ή των πληροφοριών που περιέχονται σε αυτή.</p>
      <h2>Συμπεριφορά Χρηστών</h2>
      <p>Οι χρήστες της ιστοσελίδας οφείλουν να συμμορφώνονται με τους κανόνες και τις διατάξεις του Ελληνικού, Ευρωπαϊκού και Διεθνούς Δικαίου και τη σχετική νομοθεσία που διέπει τις τηλεπικοινωνίες και να απέχουν από κάθε παράνομη και καταχρηστική συμπεριφορά κατά τη χρήση αυτής και σε σχέση με αυτήν. Ο χρήστης της ιστοσελίδας ευθύνεται για οποιαδήποτε ζημία προκληθεί στον διαδικτυακό τόπο της SoulDesign, αναγόμενη στη κακή ή αθέμιτη χρήση της ιστοσελίδας και των υπηρεσιών που προσφέρονται μέσω αυτής.</p>
      <h2>Επικοινωνία</h2>
      <p>Για κάθε είδους πληροφορίες, διευκρινίσεις, υποβολή οποιουδήποτε αιτήματος, όπως μεταξύ άλλων επικοινωνία με το διαχειριστή της ιστοσελίδας ή αίτημα για δικαιώματα αναπαραγωγής οποιουδήποτε τμήματος του περιεχομένου της ιστοσελίδας, καθώς και για αιτήσεις έγκρισης αναπαραγωγής περιεχομένου μπορείτε να επικοινωνείτε με το τμήμα εξυπηρέτησης πελατών στο 2103416904, ή να αποστείλετέ ηλεκτρονικό μήνυμα στο email: hello@souldesign.gr</p>

      {popupIsOpen && <CookiePopup dispatch={dispatch} value={state} />}
    </div>
    )}
  </CookieConsentContext.Consumer>
  )

export default TermsPage
